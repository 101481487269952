export const getLuxorMapData = () => {
    return [
        {
            mapBg: require('@/assets/maps/bg-luxor-route.jpg'),
            mapLine: 'LuxorPath',
            venueBg:  require('@/assets/maps/luxor.png'),
            venue: 'Luxor',
            distance: '0.7',
            singleLineVenue: true,
            threeLineVenue: false
        }
    ]
}
